/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

// Reset global styles
*,
:after,
:before {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  box-sizing: border-box;
  border: 0 none;
  outline: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

a {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

ul {
  padding-left: 2.5em;
  list-style: disc !important;
}

ol {
  padding-left: 2.5em;
  list-style: decimal !important;
}

ol li::marker {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

table {
  width: 100%;
  border-collapse: collapse !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #828282;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  z-index: 99;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #828282;
  border-radius: 10px;
  // border: 2px solid #ffffff;
}

// NGX-EDITOR
.NgxEditor__Content {
  padding: 16px 16px 16px 48px !important;
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  overflow-y: auto;
}

.editor-content,
.editor {
  a {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: inherit;
  }
}

.editor {
  border-radius: 12px;
  max-width: 1440px;
  min-width: 318px;

  .NgxEditor__MenuBar {
    border-top: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #eff6f6;
    padding: 16px;
    gap: 16px;

    .NgxEditor__MenuItem:hover {
      background-color: #eff6f6;
      color: #24535b;
    }

    .NgxEditor__MenuItem {
      color: #747f81;
    }

    .NgxEditor__MenuItem .NgxEditor__MenuItem--Active {
      background-color: #eff6f6;
      color: #24535b;
    }
    .NgxEditor__MenuItem.NgxEditor__MenuItem--Icon {
      height: 24px;
      width: 24px;
      transition: 0.3s ease-in-out;
    }

    .NgxEditor__MenuItem.NgxEditor__MenuItem--Icon
      .NgxEditor__MenuItem--Active {
      height: 24px;
      width: 24px;
      transition: 0.3s ease-in-out;
    }
  }

  ngx-color-picker .NgxEditor__Popup button,
  ngx-link .NgxEditor__Popup button {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    background-color: #d1d8d9;
    padding: 4px;
    border-radius: 8px;
  }

  ngx-dropdown .NgxEditor__Dropdown--Selected {
    color: #091315;
    background-color: #eff6f6;
  }

  ngx-link .NgxEditor__Popup input {
    border-bottom: 1px solid #24535b;
  }

  .NgxEditor {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #eff6f6;
  }

  .CodeMirror {
    border: 1px solid #eff6f6;
    height: auto;
    margin-bottom: 0.7rem;

    pre {
      white-space: pre !important;
    }
  }
}

// MAT-FORM-INPUT
.mat-form-field-appearance-fill:not(.mat-form-field-disabled)
  .mat-form-field-flex {
  border-radius: 12px !important;
  background-color: #eff6f6 !important;
  padding: 8px 16px !important;
  height: 54px !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  border-radius: 12px !important;
  background-color: #d1d8d9 !important;
  padding: 8px 16px !important;
  height: 54px !important;
}

.mat-form-field-appearance-fill.read-only .mat-form-field-flex {
  background-color: #d1d8d9 !important;
}

.mat-form-field.mat-focused.read-only .mat-form-field-flex {
  border: none;
}

.mat-form-field.mat-focused .mat-form-field-flex {
  border: 1px solid #24535b;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-flex {
  border: 1px solid #eb4c60;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-form-field:not(.mat-focused) .mat-form-field-label {
  color: #747f81;
  font-size: 16px;
  line-height: 20px;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

input.mat-input-element {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

input.mat-input-element::placeholder {
  font-weight: 400 !important;
  color: #747f81 !important;
}

.mat-form-field.center .mat-form-field-infix {
  padding: 0.5em 0 0.5em 0px;
  border-top: 0px;
}

.mat-form-field.no-error .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.mat-form-field.pr26 .mat-form-field-infix {
  padding-right: 26px;
}

.mat-form-field.pr48 .mat-form-field-infix {
  padding-right: 48px;
}

.mat-form-field.unit-tooltip .mat-form-field-infix {
  padding-right: 88px;
}

.mat-form-field.unit .mat-form-field-infix {
  padding-right: 48px;
}

// MAT-TEXTAREA

.textarea.mat-form-field-appearance-fill .mat-form-field-infix {
  height: 100%;
  padding: 0 !important;
  border-top: 0 !important;
}

.textarea.rental-notes.mat-form-field-appearance-fill
  .mat-form-field-flex
  .mat-form-field-prefix {
  height: 100% !important;
}

textarea.mat-input-element {
  height: 100% !important;
  resize: none !important;
  padding: 2px 0 2px 10px !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.textarea.rental-notes.mat-form-field-appearance-fill .mat-form-field-flex {
  min-height: 108px;
}

// MAT-BUTTON
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #24535b;
  border-radius: 90px;
  height: 54px;
  font-size: 1em;
  font-weight: 600;
  line-height: 20px;
}

.mat-flat-button.mat-primary:not(.mat-button-disabled):hover,
.mat-raised-button.mat-primary:not(.mat-button-disabled):hover,
.mat-fab.mat-primary:not(.mat-button-disabled):hover,
.mat-mini-fab.mat-primary:not(.mat-button-disabled):hover {
  background-color: #163439;
}

.mat-flat-button.mat-button-disabled.mat-button-disabled {
  color: #ffffff;
}

// .mat-flat-button .mat-button-wrapper span {
//   vertical-align: baseline !important;
// }

// MAT-STEPPER

.mat-horizontal-stepper-header {
  height: auto !important;
  padding: 0 12px 0 0 !important;
  pointer-events: none !important;
  cursor: pointer;
}

.mat-horizontal-stepper-header:last-child {
  padding: 0 !important;
}

.mat-horizontal-content-container {
  padding: 0 1px !important;
}

.mat-step-label {
  display: none !important;
}

.mat-step-icon {
  border-radius: 90px !important;
  height: 8px !important;
  width: 112px !important;
}

.mat-step-icon-content,
.mat-step-icon .mat-icon,
.mat-stepper-horizontal-line {
  display: none !important;
}

.mat-horizontal-stepper-header .mat-step-icon {
  margin-right: 0px !important;
}

.mat-step-icon.mat-step-icon-selected,
.mat-step-icon.mat-step-icon-state-edit {
  background-color: #eb4c60 !important;
}

.mat-step-icon.mat-step-icon-state-number {
  background-color: #e5eae4;
}

// Ngx-INTL-TEL-INPUT

.iti.iti--allow-dropdown {
  width: 100%;
}

.iti .selected-dial-code {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.iti .country-dropdown {
  max-width: 360px;
}

.iti.iti--allow-dropdown input:not(#country-search-box) {
  padding-left: 104px !important;
  padding: 17px 10px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  background-color: #eff6f6;
  width: 100% !important;
}

.iti__selected-flag.dropdown-toggle {
  border-right: 1px solid #d1d8d9;
}

ngx-intl-tel-input.ng-untouched input {
  outline: 0;
}

ngx-intl-tel-input.ng-invalid .submitted {
  outline: 1px solid #eb4c60 !important;
  transition: all 0.3s;
}

ngx-intl-tel-input.ng-invalid input.ng-touched {
  outline: 1px solid #eb4c60 !important;
  transition: all 0.3s;
}

ngx-intl-tel-input input:focus {
  outline: 1px solid #24535b;
  transition: all 0.3s;
}

// MAT-TOOLTIP
.sidemenu-tooltip {
  padding: 20px 40px !important;
  border-radius: 90px !important;
  background: #091315;
  font-size: 16px;
  line-height: 20px;
}

.tooltip-message {
  padding: 12px !important;
  border-radius: 16px !important;
  background: #091315;
  font-size: 14px;
  line-height: 18px;
}

// NGX-GALLERY
.front-page-image-slider gallery {
  height: 280px;
  border-radius: 28px;
}

// MAT-TAB-LABEL
.my-profile-tab .mat-tab-label {
  opacity: 1 !important;
  padding: 0 !important;
  min-width: auto !important;
}

.my-profile-tab .mat-tab-body-wrapper {
  height: 100%;
}

.my-profile-tab .mat-tab-label:not(:last-child) {
  margin-right: 28px;
}

.my-profile-tab .mat-tab-label .mat-tab-label-content {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #283f43;
}

.my-profile-tab .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
  color: #eb4c60;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #eb4c60;
}

// .my-profile-tab.extend-tab .mat-tab-body-wrapper {
//   margin-left: -40px;
//   padding-right: 40px;
//   padding-left: 40px;
//   width: calc(100% + 80px);
// }
// .my-profile-tab.extend-tab .mat-tab-body {
//   margin-left: -40px;
//   padding-left: 40px;
//   width: calc(100% + 80px);
// }
// .my-profile-tab.extend-tab .mat-tab-body-content {
//   margin-left: -40px;
//   padding-right: 40px;
//   padding-left: 40px;
//   width: calc(100% + 80px);
// }

// MAT-SELECT-BOX

.mat-select-panel {
  padding: 4px 8px 4px 8px !important;
}

.mat-select-panel .mat-option {
  border-radius: 8px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mat-select-arrow {
  opacity: 0;
}

.select-box .mat-form-field-infix {
  border-top: 0 !important;
  padding: 0.5em 0 !important;
}

.select-box.monthpicker .mat-form-field-infix {
  border-top: 0 !important;
  padding: 0.5em 0 !important;
  bottom: 0.3em !important;
}

.color-747f81 .mat-select-placeholder {
  color: #747f81;
}

.text-size-16 .mat-select-placeholder {
  font-size: 16px;
}

.line-height-20 .mat-select-placeholder {
  line-height: 20px;
}

.color-091315 .mat-select-value-text {
  color: #091315;
}

.text-size-16 .mat-select-value-text {
  font-size: 16px;
}

.line-height-20 .mat-select-value-text {
  line-height: 20px;
}

.font-weight-600 .mat-select-value-text {
  font-weight: 600;
}

.padding-52 .mat-select-value {
  padding-right: 52px;
}
.padding-16 .mat-select-value {
  padding-right: 16px;
}

.text-size-16 .mat-option {
  font-size: 16px;
}

.line-height-20 .mat-option {
  line-height: 20px;
}

.color-1b2022 .mat-option {
  color: #1b2022;
}

.mat-select-panel .mat-option .mat-pseudo-checkbox-checked {
  background: #24535b !important;
}

.mat-select-panel .mat-option .mat-pseudo-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 7px;
}

.mat-select-panel .mat-option .mat-pseudo-checkbox-checked::after {
  top: 5px;
  left: 4px;
  width: 10px;
  height: 4px;
}

// MAT-SELECT SEARCH
.mat-select-search-inside-mat-option .mat-select-search-input {
  // padding-top: 0;
  // padding-bottom: 0;
  // height: 3em;
  line-height: 1.25em !important;
}

.mat-select-search-inner.mat-select-search-inner-multiple {
  width: calc(100% + 3em) !important;
}

// MAT-DIAGLOG
.mat-dialog-container {
  border-radius: 32px !important;
  padding: 32px !important;
}

// .mat-dialog-content {
//   max-height: 80vh !important;
// }

// MAT-DATEPICKER

.mat-calendar-period-button {
  pointer-events: none;
}

// MAT-RADIOBUTTON
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #24535b;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #24535b;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
  background-color: #24535b;
}

.mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-persistent-ripple
  .mat-ripple-element {
  background-color: #24535b;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #24535b !important;
}

// MAT-CHECKBOX
.mat-checkbox-inner-container {
  height: 24px !important;
  width: 24px !important;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 7px !important;
}

.mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: #24535b !important;
}

.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #24535b !important;
}

// .save-as-invoice.mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
//   background-color: #eb4c60 !important;
// }

// MAT-MENU
.mat-menu-content {
  padding: 8px !important;
}

button.mat-menu-item {
  border-radius: 8px;
}

// MAT-SLIDER

// .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
//   height: 8px;
//   border-radius: 90px;
// }
// .mat-slider.mat-slider-horizontal .mat-slider-track-background,
// .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
//   height: 100%;
// }
// .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
//   background-color: #24535b;
// }
// .mat-accent .mat-slider-thumb {
//   height: 24px;
//   width: 24px;
//   background-color: #24535b;
//   bottom: -14px;
//   right: -14px;
// }
// .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
//   background-color: #24535b;
// }

// .mat-slider-track-background {
//   background-color: #e5eae4;
// }

// .mat-slider.mat-accent .mat-slider-thumb,
// .mat-slider.mat-accent .mat-slider-thumb-label {
//   background-color: #24535b;
// }

// .mat-slider.mat-accent .mat-slider-thumb-label-text {
//   color: #ffffff !important;
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 18px;
//   font-family: "Plus Jakarta Sans", sans-serif;
// }

// .mat-slider.mat-accent .mat-slider-thumb-label {
//   width: 50px;
//   height: 50px;
// }

// .mat-slider-horizontal .mat-slider-thumb-label {
//   top: -62px !important;
//   right: -24px !important;
// }

// NGX-SLIDER

.ngx-slider .ngx-slider-bar {
  background: #e5eae4 !important;
  border-radius: 90px !important;
}

.ngx-slider .ngx-slider-selection {
  background: #24535b !important;
  border-radius: 90px !important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: #24535b !important;
}

// MAT-CHIP

.mat-chip.mat-standard-chip {
  background-color: #e5eae4;
  color: #091315;
}

.mat-chip-list-wrapper .mat-standard-chip {
  padding: 27px !important;
  border-radius: 90px;
}

.mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  width: auto !important;
  height: auto !important;
}
